.imageSize {
    height: initial;
    width: 100%;
}
@media (min-width: 768px) {
    .contentsArticles {
        display: flex;
        justify-content: space-between;
    }
    .reversImage {
        float: left !important;
    }

}
