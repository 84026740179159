@font-face {
    font-family: 'bahnschrift';
    src: url('../../assets/fonts/BAHNSCHRIFT.TTF');
}

* {
    font-family: bahnschrift, Arial, Helvetica, sans-serif;
}
html, body {
    overflow-x: hidden;
}

#bgColor {
    background-color: #2C1550;
    color: white;
}
.colorTitle {
    color: #FFFF01;
}