h1 {
  font-size: 30px;
}

#iconeContrat {
  height: 70px;
}

#containerBanner {
  z-index: 100;
  background-image: url("../../assets/images/backgroundOuverture.png");
  background-position-x: center;
  background-size: auto 100%;
  z-index: 1;
  margin-top: 95px;
}
#containerBanner section:first-child {
  height: 400px;
}
#containerBanner section:nth-child(2) {
  height: 600px;
}
.fsDescription {
  width: 90%;
}
.containerContact {
  border: 3px solid #ffed00;
  height: 240px;
  width: 280px;
  padding: 5px 40px;
  z-index: 100;
  transition: transform 0.5s ease;
  transition: background-color 0.5s ease;
}

.containerContact:hover {
  background-color: #5C317A;
}

#containerButtons img {
  height: 60px;
}

#logoSwitch {
  height: 50px !important;
  width: 115px;
}

@media (min-width: 576px) {
  #containerButtons img {
    height: 80px;
  }
}

@media (min-width: 768px) {
  #containerBanner {
    background-image: url("../../assets/images/backgroundOuverture.png");
    background-position-y: -100px;
    background-size: 1500px;
  }

  #containerBanner h2 {
    font-size: 45px;
    max-width: 650px !important;
  }

  #iconeContrat {
    height: 125px;
  }

  #containerButtons {
    z-index: 15;
  }
}

@media (min-width: 992px) {
  #containerBanner {
    background-position-y: 0px;
    background-size: 100% auto;
  }
  #containerBanner h2 {
    width: 50% !important;
    font-size: 60px !important;
  }

  #iconeContrat {
    height: 200px;
  }

  .positionContentBanner {
    padding-bottom: 310px !important;
  }

  .moveButtons {
    top: 200px !important;
  }
  #containerBanner section:first-child {
    height: 600px;
  }
}

@media (min-width: 1200px) {
  #containerBanner h2 {
    max-width: 850px !important;
  }
  .fsDescription {
    width: 70%;
  }
  #containerBanner {
    background-position-y: -160px;
    background-size: 100% auto;
    height: 800px;
  }
}
