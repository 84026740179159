
#formBanner {
    background: #5C317A;
    height: 200px;
    margin-top: 95px;
}

#formBanner h1{
    font-size: 1.9rem;
    font-weight: 700 !important;
}
@media (min-width: 992px) {
    
    #formBanner h1{
        font-size: 4rem;
    }
}