#headerDescription section:first-child{
    background-color: #200E46;
    height: 205px;
}
#headerDescription {
    height: auto ;
}

.iconeDescription {
    height: 105px;
}
#iconePower {
    top: -52.5px;
}
#iconeEasy {
    top: 145px;
}
h3 {
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 2px;
}
#containerResume {
    height: 650px;
}
#containerResume img {
    width: 100px;
    height: 100px;
   
}

.fsDescription {
    font-size: 17px;
}
@media (min-width: 576px) {
    
    #containerResume {
        height: 400px;
    }
}

@media (min-width: 768px) {
    #containerResume {
        height: 300px;
    }
    #headerDescription {
        margin-top: 100px !important;
        
    }
    #headerDescription h3 {
        font-size: 25px !important;
        line-height: initial;
    }
    #iconePower {
        position: initial !important;
    }
    #iconeEasy {
        position: initial !important;
    }
    .iconeDescription {
        margin-bottom: 15px;
        height: 130px;
    }
    .fsDescription {
        font-size: 22px;
    }
    
}
@media (min-width: 992px) {
    #headerDescription {
        margin-top: 200px !important;
    }
    #headerDescription section:first-child{
        height: 110px;
    }
    h3 {
        font-size: 45px;
    }
    .iconeDescription {
        height: 185px;
        margin-bottom: 14px;
    }
 
}
