header p {
    text-decoration: none;
    color: #5C317A;
    font-size: 12px;
}
header {
    background-color: #2C1550;
    height: 60px;
    box-shadow: 0px 22px 10px #00000029;
}

#containerLogoSwitch a,
#containerLogoCe a {
    display: block;
    height: 100%;
}
@media (min-width: 576px) {
    header {
        height: 95px;
    }
    .contactNum p,
    header p {
        font-size: 20px;
    
    }
    
}

@media (min-width: 992px) {
    #containerLogoSwitch {
        position: relative;
    }
    
    #containerLogoSwitch,
    #containerLogoCe {
        height: 70px;
        display: initial !important;
    }
    
    img {
        height: 70px;
    }
    
    
}